import { useEffect, useState } from 'react'
import { datadogReportError } from 'events/datadog'
import { SubscriptionResponse } from 'components/_utils/subscriptionUtils'

const INTERVAL_LIMIT = 40
export const useGetMemberDataFromAppInjection = () => {
	const [subscriptionInformation, setSubscriptionInformation] = useState<SubscriptionResponse>(null)
	const [limitReached, setLimitReached] = useState(false)

	useEffect(() => {
		const queryAuthToken = getTokenFromBrowser()

		const processAuthToken = (authToken: string) => {
			getUserSubscriptionInfo(authToken).then((subscriptionInfo) => {
				sessionStorage?.setItem('subscriptionInformation', JSON.stringify(subscriptionInfo))
				setSubscriptionInformation(subscriptionInfo)
				setLimitReached(true)
			})
		}

		if (queryAuthToken) {
			processAuthToken(queryAuthToken)
			return () => {}
		}

		let intervalCounter = 0
		const interval = setInterval(() => {
			const authToken = getTokenFromBrowser()
			if (++intervalCounter > INTERVAL_LIMIT) {
				clearInterval(interval)
				setLimitReached(true)
				return
			}

			if (!authToken) return

			// If we have an auth token, we don't need to refresh anymore.
			// We just want to wait until we get a response back from the auth endpoint.
			// If the user is valid, we'll get it, otherwise we'll redirect to login.
			intervalCounter = 0
			clearInterval(interval)
			processAuthToken(authToken)
		}, 100)

		return () => clearInterval(interval)
	}, [])

	useEffect(() => {
		if (sessionStorage?.getItem('subscriptionInformation')) {
			setSubscriptionInformation(JSON.parse(sessionStorage?.getItem('subscriptionInformation')))
			setLimitReached(true)
		}
	}, [])

	return {
		subscriptionInformation,
		limitReached,
	}
}

const getUserSubscriptionInfo = async (authToken: string) => {
	const apiFetch = await fetch(`/api/membership-details/auth?jwt=${authToken}`, {
		method: 'GET',
	}).catch((reason) => {
		datadogReportError(reason, 'Pod4 stories to get user data')
	})

	if (!apiFetch) {
		return null
	}

	return await apiFetch.json()
}

/**
 * @returns The auth token from the browser.
 */
export const getTokenFromBrowser = (): string | null => {
	if (typeof window === 'undefined') {
		return null
	}

	const searchParams = new URLSearchParams(window.location.search)
	const authToken = searchParams.get('authToken')
	const token = searchParams.get('token')
	if (token) {
		return token
	}

	if (authToken) {
		return authToken
	}

	const sessionAuthToken = sessionStorage.getItem('authToken')
	if (sessionAuthToken) {
		return sessionAuthToken
	}

	const sessionToken = sessionStorage.getItem('token')
	if (sessionToken) {
		return sessionToken
	}

	const localStorageAuthToken = localStorage.getItem('authToken')
	if (localStorageAuthToken) {
		return localStorageAuthToken
	}

	const localStorageToken = localStorage.getItem('token')
	if (localStorageToken) {
		return localStorageToken
	}

	return null
}
