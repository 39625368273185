import { CartLineItem } from 'cart'
import { datadogReportEvent } from 'events/datadog'

// Define types for Twitter tracking
type TwitterEventName = 'tw-nw40a-ocwxn' | 'tw-nw40a-o2a9m' | 'tw-nw40a-o1zr9'
type TwitterEventParams = Record<string, any>

declare global {
	interface Window {
		twq?: (command: 'event', eventName: TwitterEventName, parameters?: TwitterEventParams) => void
	}
}

const trackTwq = (eventName: TwitterEventName, parameters: TwitterEventParams = {}) => {
	if (typeof window !== 'undefined' && window.twq) {
		window.twq('event', eventName, parameters)
		return true
	}
	return false
}

export const twPageView = () => {
	try {
		if (trackTwq('tw-nw40a-ocwxn', {})) {
			datadogReportEvent('success', 'twPageView', {})
		}
	} catch (e) {
		console.log(e)
		datadogReportEvent('failure', 'twPageView', e)
	}
}

export const twAddToCart = (item: CartLineItem) => {
	try {
		// Uncomment and use this version if you want to track detailed cart data
		// const params = {
		// 	value: item.price,
		// 	currency: item.prices.currency,
		// 	contents: [
		// 		{
		// 			content_id: item.variantId,
		// 			num_items: item.quantity,
		// 			content_name: item.productTitle,
		// 		},
		// 	],
		// }
		if (trackTwq('tw-nw40a-o2a9m', {})) {
			datadogReportEvent('success', 'twAddToCart', {})
		}
	} catch (e) {
		console.log(e)
		datadogReportEvent('failure', 'twAddToCart', e)
	}
}

export const twInitiateCheckout = (items: CartLineItem[]) => {
	try {
		// Uncomment and use this version if you want to track detailed checkout data
		// const totalPrice = items.reduce((total, lineItem) => total + lineItem.price, 0)
		// const params = {
		// 	value: totalPrice,
		// 	currency: items[0].prices.currency,
		// 	contents: items.map((item) => ({
		// 		content_id: item.variantId,
		// 		num_items: item.quantity,
		// 		content_name: item.productTitle,
		// 	})),
		// }
		if (trackTwq('tw-nw40a-o1zr9', {})) {
			datadogReportEvent('success', 'twInitiateCheckout', {})
		}
	} catch (e) {
		console.log(e)
		datadogReportEvent('failure', 'twInitiateCheckout', e)
	}
}
