import ReactPixel from 'utils/reactPixel'
import { AffirmLocations } from 'constants/events'
import { sendFBEvents } from 'eight_api/aws'
import { sha256 } from 'js-sha256'
import { CartLineItem } from 'cart/types'

export interface AdvancedMatchingParams {
	ct?: string
	country?: string
	em?: string
	external_id?: string
	fn?: string
	ln?: string
	ph?: string
	st?: string
	zp?: number
}

export const getEventIdHash = () => {
	const uuid = localStorage.getItem('eight-uuid')
	const now = Date.now()
	if (uuid) {
		return sha256(uuid + now)
	} else {
		return sha256(now.toString())
	}
}

const fbEventIdentification = {
	AddToCart: () => {
		return {
			eventID: 'event.AddToCart.' + getEventIdHash(),
			event: 'AddToCart',
		}
	},
	PageView: () => {
		return {
			eventID: 'event.PageView.' + getEventIdHash(),
			event: 'PageView',
		}
	},
	ViewContent: () => {
		return {
			eventID: 'event.ViewContent.' + getEventIdHash(),
			event: 'ViewContent',
		}
	},
	SubscribeEmail: () => {
		return {
			eventID: 'event.SubscribeEmail.' + getEventIdHash(),
			event: 'SubscribeEmail',
		}
	},
	ClickFAQ: () => {
		return {
			eventID: 'event.ClickFAQ.' + getEventIdHash(),
			event: 'ClickFAQ',
		}
	},
	ClickAffirmLearnMore: () => {
		return {
			eventID: 'event.ClickAffirmLearnMore.' + getEventIdHash(),
			event: 'ClickAffirmLearnMore',
		}
	},
	Subscribe: () => {
		// Should be used for product subscriptions, not email signup
		return {
			eventID: 'event.Subscribe.' + getEventIdHash(),
			event: 'Subscribe',
		}
	},
	ClickCheckoutPayment: () => {
		return {
			eventID: 'event.ClickCheckoutPayment.' + getEventIdHash(),
			event: 'ClickCheckoutPayment',
		}
	},
	CompleteContactInformation: () => {
		return {
			eventID: 'event.CompleteContactInformation.' + getEventIdHash(),
			event: 'CompleteContactInformation',
		}
	},
	CompletePaymentMethodSelection: () => {
		return {
			eventID: 'event.CompletePaymentMethodSelection.' + getEventIdHash(),
			event: 'CompletePaymentMethodSelection',
		}
	},
	InitiateCheckout: () => {
		return {
			eventID: 'event.InitiateCheckout.' + getEventIdHash(),
			event: 'InitiateCheckout',
		}
	},
	CheckoutPaymentSelect: () => {
		return {
			eventID: 'event.CheckoutPaymentSelect.' + getEventIdHash(),
			event: 'CheckoutPaymentSelect',
		}
	},
	ViewedPodShop2xIn90Days: () => {
		return {
			eventID: 'event.ViewedPodShop2xIn90Days.' + getEventIdHash(),
			event: 'ViewedPodShop2xIn90Days',
		}
	},
	ViewedPodShop2xIn28Days: () => {
		return {
			eventID: 'event.ViewedPodShop2xIn28Days.' + getEventIdHash(),
			event: 'ViewedPodShop2xIn28Days',
		}
	},
	AddThermoCoverToCart: () => {
		return {
			eventID: 'event.AddThermoCoverToCart.' + getEventIdHash(),
			event: 'AddThermoCoverToCart',
		}
	},
}

// AddToCart
export const fbAddToCart = (item: Partial<CartLineItem>) => {
	const eventIdentification = fbEventIdentification.AddToCart()

	ReactPixel.track(
		'AddToCart',
		{
			content_ids: [item.productId?.toString()],
			content_name: item.productTitle,
			content_type: 'product',
			contents: item.productTitle,
			currency: 'USD',
			value: item.price / 100,
			quantity: 1,
		},
		eventIdentification.eventID
	)

	// legacy custom event
	ReactPixel.trackCustom(
		'Add to Cart',
		{
			content_ids: [item?.productId?.toString()],
			content_name: item.productTitle,
			content_type: 'product',
			contents: item.productTitle,
			currency: 'USD',
			value: item.price / 100,
			quantity: 1,
		},
		eventIdentification.eventID
	)

	sendFBEvents([
		{
			type: 'AddToCart',
			content_ids: item.productId,
			content_name: item.productTitle,
			content_type: 'product',
			contents: item.productTitle,
			currency: 'USD',
			value: item.price / 100,
			quantity: 1,
			url: window.location.href,
			...eventIdentification,
		},
	])
}

export const fbPageView = () => {
	const eventIdentification = fbEventIdentification.PageView()
	if (ReactPixel?.track) {
		ReactPixel.pageView(eventIdentification.eventID)
	}

	sendFBEvents([
		{
			type: 'PageView',
			url: window.location.href,
			...eventIdentification,
		},
	])
}

export const fbViewContent = (item?: any) => {
	const eventIdentification = fbEventIdentification.ViewContent()
	ReactPixel.track('ViewContent', { ...item, content_ids: [item?.content_ids?.toString()] }, eventIdentification.eventID)

	sendFBEvents([
		{
			type: 'ViewContent',
			...item,
			url: window.location.href,
			...eventIdentification,
		},
	])
}

// SubscribeEmail
export const fbSubscribeEmail = (email: string, location: string) => {
	const eventIdentification = fbEventIdentification.SubscribeEmail()
	ReactPixel.track(
		'SubscribeEmail',
		{
			location,
		},
		eventIdentification.eventID
	)

	sendFBEvents([
		{
			type: 'SubscribeEmail',
			location: location,
			url: window.location.href,
			...eventIdentification,
		},
	])
}

export const fbSelectPaymentMethod = (paymentMethod: string) => {
	const eventIdentification = fbEventIdentification.CheckoutPaymentSelect()
	ReactPixel.track(
		'CheckoutPaymentSelect',
		{
			paymentMethod,
		},
		eventIdentification.eventID
	)

	sendFBEvents([
		{
			type: 'CheckoutPaymentSelect',
			paymentMethod: paymentMethod,
			url: window.location.href,
			...eventIdentification,
		},
	])
}

export const fbInitiatedPayment = (paymentMethod: string) => {
	const eventIdentification = fbEventIdentification.ClickCheckoutPayment()
	ReactPixel.track(
		'ClickCheckoutPayment',
		{
			paymentMethod,
		},
		eventIdentification.eventID
	)

	sendFBEvents([
		{
			type: 'ClickCheckoutPayment',
			paymentMethod: paymentMethod,
			url: window.location.href,
			...eventIdentification,
		},
	])
}

// ClickFaq
export const fbClickFaq = (question: string) => {
	const eventIdentification = fbEventIdentification.ClickFAQ()
	ReactPixel.track(
		'Click - FAQ',
		{
			question,
		},
		eventIdentification.eventID
	)

	sendFBEvents([
		{
			type: 'Click - FAQ',
			question: question,
			url: window.location.href,
			...eventIdentification,
		},
	])
}

// ClickAffirmLearnMore
export const fbClickAffirmLearnMore = (location: AffirmLocations) => {
	const eventIdentification = fbEventIdentification.ClickAffirmLearnMore()
	ReactPixel.track(
		'Click - Learn more Affirm',
		{
			location,
		},
		eventIdentification.eventID
	)

	sendFBEvents([
		{
			type: 'Click - Learn more Affirm',
			location: location,
			url: window.location.href,
			...eventIdentification,
		},
	])
}

// Subscribe
export const fbSubscribe = (email: string, location: string) => {
	const eventIdentification = fbEventIdentification.Subscribe()
	ReactPixel.track(
		'Subscribe',
		{
			'Subscribe Location': location,
		},
		eventIdentification.eventID
	)

	sendFBEvents([
		{
			type: 'Subscribe',
			location: location,
			url: window.location.href,
			...eventIdentification,
		},
	])
}

// InitiateCheckout
export const fbInitiateCheckout = (itemNames: string[]) => {
	const eventIdentification = fbEventIdentification.InitiateCheckout()
	ReactPixel.trackCustom(
		`Checkout - Initiated`,
		{
			'Source Page URL': window.location.href,
			itemNames,
		},
		eventIdentification.eventID
	)

	sendFBEvents([
		{
			type: 'Checkout - Initiated',
			itemNames: itemNames,
			url: window.location.href,
			...eventIdentification,
		},
	])
}

// CompleteContactInformation
export const fbCompleteContactInformation = (advancedConfig: AdvancedMatchingParams) => {
	const eventIdentification = fbEventIdentification.CompleteContactInformation()
	ReactPixel.trackCustom(`Checkout - Complete Contact Info`, {}, eventIdentification.eventID)

	sendFBEvents([
		{
			type: 'Checkout - Complete Contact Info',
			...eventIdentification,
			...advancedConfig,
			url: window.location.href,
		},
	])
}

// ClickContinueToPaymentProvider
export const fbClickContinueToPaymentProvider = (name, itemNames: string[], advancedConfig: AdvancedMatchingParams) => {
	const event1Identification = fbEventIdentification.ClickCheckoutPayment()
	ReactPixel.trackCustom(`Initiated Payment - ${name}`, { 'Source Page URL': window.location.href }, event1Identification.eventID)

	const event2Identification = fbEventIdentification.CompletePaymentMethodSelection()
	const formattedPaymentName = name === 'recharge' ? 'pay now - recharge' : name
	ReactPixel.trackCustom(
		`Checkout - Completed Payment Method`,
		{
			paymentMethod: formattedPaymentName,
			itemNames,
		},
		event2Identification.eventID
	)

	sendFBEvents([
		{
			type: `Initiated Payment - ${name}`,
			url: window.location.href,
			...event1Identification,
			...advancedConfig,
		},
		{
			type: 'Checkout - Completed Payment Method',
			paymentMethod: formattedPaymentName,
			itemNames: itemNames,
			url: window.location.href,
			...event2Identification,
			...advancedConfig,
		},
	])
}

// ViewedPodShop2xIn90Days
export const fbViewedPodShop2xIn90Days = () => {
	const eventIdentification = fbEventIdentification.ViewedPodShop2xIn90Days()
	ReactPixel.trackCustom('Viewed Pod Shop 2x in 90 days', {}, eventIdentification.eventID)

	sendFBEvents([
		{
			type: 'Viewed Pod Shop 2x in 90 days',
			url: window.location.href,
			...eventIdentification,
		},
	])
}

// ViewedPodShop2xIn28Days
export const fbViewedPodShop2xIn28Days = () => {
	const eventIdentification = fbEventIdentification.ViewedPodShop2xIn28Days()
	ReactPixel.trackCustom('Viewed Pod Shop 2x in 28 days', {}, eventIdentification.eventID)

	sendFBEvents([
		{
			type: 'Viewed Pod Shop 2x in 28 days',
			url: window.location.href,
			...eventIdentification,
		},
	])
}

// AddThermoCoverToCart
export const fbAddThermoCoverToCart = () => {
	const eventIdentification = fbEventIdentification.AddThermoCoverToCart()
	ReactPixel.trackCustom('Add to Cart - Thermo Cover', {}, eventIdentification.eventID)

	sendFBEvents([
		{
			type: 'Add to Cart - Thermo Cover',
			url: window.location.href,
			...eventIdentification,
		},
	])
}
